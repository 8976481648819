import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Screen from "../screen";
import Message from "../message";

export default function Router() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Message text="NiteOwl" />,
    },
    {
      path: "hangout/:eventId",
      element: <Screen />,
      errorElement: (
        <Message text="Malformed URL. Please check your URL and try again" />
      ),
    },
    {
      path: "*",
      element: <Message text="This page isn't available. Sorry about that." />,
    },
  ]);
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
