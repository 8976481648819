import React from "react";

export default function Message({ text = "" }) {
  return (
    <div style={styles.container}>
      <div style={styles.text}>{text}</div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
  },
  text: {
    margin: 0,
    color: "white",
    fontSize: 30,
  },
};
